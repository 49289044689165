import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

const url = window.location.href;             //获取当前url

console.log(url)
const cs = url.split('?')[1];                //获取?之后的参数字符串
console.log(cs)

const cs_arr = cs.split('&');                    //参数字符串分割为数组
const params = new Map();
for(let i=0; i<cs_arr.length; i++){         //遍历数组，拿到json对象
    const key = cs_arr[i].split('=')[0]
    params.set(key, cs_arr[i].split('=')[1])
}

// const SDKAppID = 1400781286; // Your SDKAppID
const SDKAppID = 1400795258; // Your SDKAppID prod

// init TUIKit
const TUIKit = TUICore.init({
    SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// login TUIKit
TUIKit.login({
    userID: params.get("imuid"),
    userSig: params.get("imsign")
});

createApp(App).use(TUIKit).mount('#app');
