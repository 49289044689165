
import TUIAegis from '../../../../../utils/TUIAegis';
import { onClickOutside } from '@vueuse/core';
import { defineComponent, reactive, watchEffect, toRefs, ref } from 'vue';
import Link from '../../../../../utils/link';
const uuid = require('uuid')
const Evaluate = defineComponent({
  type: 'custom',
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      link: 'https://web.sdk.qcloud.com/im/doc/zh-cn//SDK.html#createCustomMessage',
      list: 5,
      show: false,
      isMute: false,
      options: {
        data: {
          businessID: "INVITE_RATING",
          type: "INVITE_RATING"
        },
        description: '已邀请用户进行评价',
        extension: '已邀请用户进行评价',
      },
      num: 0,
    });

    const dialog: any = ref();

    onClickOutside(dialog, () => {
      data.show = false;
    });

    watchEffect(() => {
      data.show = props.show;
      data.isMute = props.isMute;
    });

    const toggleShow = () => {
      data.show = !data.show;
      // if (data.show) {
      //   data.options = {
      //     data: {
      //       ...handleOptions(constant.typeEvaluate, 1, { score: '', comment: '' }),
      //     },
      //     description: '已邀请用户进行评价',
      //     extension: '已邀请用户进行评价',
      //   };
      //   data.num = 0;
      // }
    };

    const select = (item: any, index: number) => {
      data.num = index + 1;
      (data.options.data as any).score = `${data.num}`;
    };

    const submit = () => {
      Evaluate.TUIServer.sendCustomMessage({
        data: {
          hint: "您的APP版本过低，暂不支持服务评分功能，请升级后使用",
          businessID: "INVITE_RATING",
          type: "INVITE_RATING",
          data: {
            key: uuid.v4()
          }
        },
        description: '已邀请用户进行评价',
        extension: '已邀请用户进行评价',
      });
      data.show = false
    };
    const openLink = (type: any) => {
      window.open(type.url);
      TUIAegis.getInstance().reportEvent({
        name: 'openLink',
        ext1: type.label,
      });
    };
    return {
      ...toRefs(data),
      toggleShow,
      select,
      submit,
      dialog,
      Link,
      openLink,
    };
  },
});
export default Evaluate;
