
import { defineComponent, reactive, toRefs } from 'vue';
import { TUIEnv } from './TUIKit/TUIPlugin';
import TUIConversation from "@/TUIKit/TUIComponents/container/TUIConversation/index.vue";
import TUISearch from "@/TUIKit/TUIComponents/container/TUISearch/index.vue";
import TUIChat from "@/TUIKit/TUIComponents/container/TUIChat/index.vue";
import TUIAegis from "@/TUIKit/utils/TUIAegis";
import {TUICore} from "@/TUIKit";
import {handleErrorPrompts} from "@/TUIKit/TUIComponents/container/utils";

export default defineComponent({
  name: 'App',
  components: {TUIChat, TUISearch, TUIConversation},
  setup() {
    const data = reactive({
      env: TUIEnv(),
      currentModel: 'conversation',
    });
    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? 'message' : 'conversation';
    };
    return {
      ...toRefs(data),
      handleCurrentConversation,
    };
  },
  mounted() {
    window.addEventListener('message', e => {
      // alert('收到消息 from parent' + e.data.chatTo)
      const imUid = e.data.chatTo
      const msg = e.data.msg
      // handleCurrentConversation(userID, 'C2C');
      // toggleOpen();
      console.log(msg)
      const name = `C2C${imUid}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(name).then((imResponse: any) => {
        TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(imResponse.data.conversation);
        if (msg) {
          // TUICore.instance.tim.sendCustomMessage(msg)
          TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
            data: msg
          })
        }
        TUIAegis.getInstance().reportEvent({
          name: 'conversationType',
          ext1: 'TypeC2C-success',
        });
      }).catch((error: any) => {
        console.log(error)
      });
    }, false);
    TUICore.instance.tim.on(TUICore.instance.TIM.EVENT.MESSAGE_RECEIVED, (event: any)=> {
      window.parent.postMessage({ type: "NEW_MESSAGE", event: event }, "*")
    });

  },
  methods: {
  }
});

